@import "../../../../styles/_config";

.header {
  display: grid;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  padding: 1rem;
  position: relative;
}
