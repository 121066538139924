@import "../../../../styles/config";
@import "../../../../styles/mixins";

.trigger {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  border: 1px solid $stone;
  border-radius: $rounded;
  color: $color-text-dark;
  cursor: pointer;
  font-family: $font-body;
  font-size: $font-md;
  font-weight: $font-normal;
  height: 1.75rem;
  width: 12rem;
  padding-inline: 0.375rem;
  padding-block: 1px;

  &:focus-visible {
    @include ring;
  }

  &[data-disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &[data-placeholder] {
    color: $secondary;
  }
}
