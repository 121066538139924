@import "../../../styles/_config";

.root {
  --this-background: var(--background, transparent);
  --this-border-color: var(--border-color, #{$white});
  --this-foreground: var(--foreground, #{$white});

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--this-background);
  color: var(--this-foreground);
  outline-color: var(--this-border-color);
  outline-width: 1px;
  padding: 0.1rem;

  /* -------------------------------------------------------------------------- */

  /*                                    shape                                   */

  /* -------------------------------------------------------------------------- */

  &.round {
    border-radius: $rounded-sm;
  }

  &.circle {
    border-radius: $rounded-full;
  }

  /* -------------------------------------------------------------------------- */

  /*                                    color                                   */

  /* -------------------------------------------------------------------------- */
  &.neutral {
    --border-color: #{$grey};
    --background: #{$color-base};
    --foreground: #{$color-card-background-dark};
  }

  &.danger {
    --background: #{$color-danger};
  }

  &.info {
    --background: #{$color-info};
  }

  &.success {
    --background: #{$color-success};
  }

  &.warn {
    --background: #{$orange};
  }

  &.secondary {
    --background: #{$secondary};
  }

  /* -------------------------------------------------------------------------- */

  /*                                    size                                    */

  /* -------------------------------------------------------------------------- */
  &.base {
    font-size: inherit;
  }

  &.xs {
    font-size: 0.75rem;
  }

  &.sm {
    font-size: 0.875rem;
  }

  &.md {
    font-size: 1rem;
  }

  &.lg {
    font-size: 1.125rem;
  }

  &.xl {
    font-size: 1.25rem;
  }

  &.xxl {
    svg {
      font-size: 1.5rem;
    }
  }

  /* -------------------------------------------------------------------------- */

  /*                                   border                                   */

  /* -------------------------------------------------------------------------- */
  &.none {
    outline-style: hidden;
  }

  &.solid {
    outline-style: solid;
  }

  &.dashed {
    outline-style: dashed;
  }
}
