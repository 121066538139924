@import "../../../../styles/config";
@import "../../../../styles/_mixins";

.combobox {
  all: unset;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-width: 20rem;
  max-width: 34rem;

  .input {
    background-color: transparent;
    border-style: none;
    border: none;
    color: $color-text-dark;
    cursor: text;
    font-size: 0.875rem;
    line-height: 1.25rem;
    outline: none;
    overflow: hidden;
    padding-block: 0.5rem;
    width: 100%;
  }

  .expander {
    align-items: center;
    box-shadow: $shadow-sm;
    background-color: $color-base;
    border-color: $color-sidebar-lines;
    border-radius: $rounded-full;
    border-style: solid;
    border-width: 1px;
    bottom: 0;
    cursor: pointer;
    display: flex;
    height: 1.25rem;
    justify-self: flex-end;
    padding-inline: 0.125rem;
    padding-block: 0.25rem;
    right: 0;
    top: 0;

    &:focus-visible {
      @include ring;
    }

    &:focus,
    &:hover {
      border-color: $secondary-lighter;
      background-color: $secondary;

      svg {
        stroke: $secondary-contrast;
      }
    }
  }

  .options {
    background-color: white;
    border-color: solid 1px $text-color-grey;
    border-radius: 0.25rem;
    bottom: 0;
    box-shadow: $shadow-xs;
    font-size: 1rem;
    height: min-content;
    line-height: 1.5rem;
    max-height: 24rem;
    overflow: auto;
    padding-block: 0.5rem;
    padding-inline: 0.5rem;
    position: absolute;
    top: 2.5rem;
    min-width: 18rem;
    z-index: 10;

    @include scrollbar;

    @media screen and (width >= 640px) {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }

  .option {
    align-items: center;
    border-radius: 0.25rem;
    cursor: pointer;
    display: flex;
    font-weight: 400;
    justify-content: flex-start;
    line-height: 1.5rem;
    margin-block: 0.25rem;
    padding: 0.5rem;

    &[data-headlessui-state="active"] {
      background-color: rgba($color-success-lighter, 0.25);
    }

    &[data-headlessui-state="selected"] {
      font-weight: 600;
    }

    &[data-headlessui-state="active selected"] {
      background-color: rgba($color-input-background, 0.25);
      font-weight: 600;
    }
  }
}
