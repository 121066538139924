@import "../../../styles/config";

$time-interaction: 0.3s;

.spinner {
  display: inline-block;

  &::before {
    width: 100%;
    height: 100%;
    opacity: 1;
    pointer-events: auto;
  }

  &::after {
    content: "";
    position: absolute;
    display: inline-block;
    height: 45px;
    width: 45px;
    top: 50%;
    left: 50%;
    margin-top: -26px;
    margin-left: -26px;
    font-size: 10px;
    color: white;
    background: transparent;
    border-radius: 50%;
    border: 7px solid $accent;
    animation: spin 1s infinite;
    z-index: 250;
  }
}

.canvas {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $smoke;
  z-index: 1000;
  transition: all $time-interaction;
}

@keyframes spin {
  50% {
    transform: rotateZ(180deg);
    border-style: dashed;
    border-color: $primary $secondary $accent $yellow;
  }

  100% {
    transform: rotateZ(360deg);
  }
}
