.logo {
  &::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0.5em;
    left: -1em;
    z-index: 100000;
    background-image: url("./logo/biko.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 7.5em;
    height: 5.5em;
    -webkit-filter: drop-shadow(0 0.3em 0.6em #10776d);
    filter: drop-shadow(0 0.3em 0.6em #10776d);
    pointer-events: none;
  }

  .badge-environment {
    text-transform: none;
    border-radius: 5px;
    padding: 1px 5px;
    letter-spacing: 0.05em;
    top: -50%;
    transform: none;
    left: 102%;
    right: auto;
  }

  h1 {
    display: inline-flex;
    margin: 0;
    padding: 0.05em calc(8em / 32) calc(7em / 32) 1.72em;
    font-weight: 500;
    font-size: 1.5em;
    letter-spacing: calc(-1em / 32); // -1px
    text-transform: uppercase;
    line-height: 1;
    color: #fff;
    cursor: pointer;
  }
}
