@import "../../../styles/_config";

.banner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.85rem;
  min-height: 3rem;
  text-wrap: balance;

  > :first-child {
    margin-left: 1rem;
    margin-right: 0.8rem;
  }

  > :last-child {
    margin-right: 1rem;
  }
}

.banner-danger {
  background-color: $color-danger-lighter;
  border-width: 1px;
  border-style: solid;
  border-color: $color-danger-light;
}

.banner-info {
  border-width: 1px;
  border-style: solid;
  border-color: $color-info-light;
  background-color: $color-info-lighter;
}

.banner-success {
  border-width: 1px;
  border-style: solid;
  border-color: $color-success-light;
  background-color: $color-success-lighter;
}

.banner-warn {
  border-width: 1px;
  border-style: solid;
  border-color: $color-warn-light;
  background-color: $color-warn-lighter;
}
