@import '../../styles/config'
@import '../../styles/mixins'
@import '../../styles/form'

.dx-legend-box
  height: 20px
  width: 20px
  border-radius: 2px
  display: inline-block
  margin-left: -1em
  margin-bottom: -.3em
  margin-right: .5em
  background-color: #a7a7a7

  &.dx-legend-box-manual, &.dx-legend-box-customer-manual
    background-color: $manual-color
  &.dx-legend-box-ean, &.dx-legend-box-daltix-ean
    background-color: $ean-color
  &.dx-legend-box-migrated, &.dx-legend-box-customer-migrated
    background-color: $migrated-color
  &.dx-legend-box-suggestion, &.dx-legend-box-daltix-recordlinkage
    background-color: $suggestion-color
  &.dx-legend-box-daltix-country
    background-color: $country-match-color
  &.dx-legend-box-daltix-shopmapping
    background-color: $shop-match-color

.filter-fieldset
    border: 1px solid #ddd
    border-radius: .5em
    padding-bottom: 1.5em
    color: #aaa
    margin-top: 1em

.shortcuts
  display: inline-flex
  font-size: 14px
  color: $text-color-grey
  .label
    font-family: Roboto,sans-serif
    font-size: 1em
    margin-left: 1em
  a
    margin-left: 1em
    margin-right: 1em
    text-decoration: none
    color: $text-color-grey
    @include transition(color .2s)
  a:hover
    margin-left: 1em
    margin-right: 1em
    text-decoration: none
    color: darken($text-color-grey, 10%)

.dx-table-line.dx-table-head
  .dx-table-cell
    font-size: 1.2rem

.country-select
  margin-bottom: 8px

.category-selector
  margin-bottom: 0px
  margin-top: 4px

.ignore-dx-clear
  #ref-prod-options-select
    padding-bottom: 8px
    padding-left: 8px
    padding-top: 8px
