@import "../../../styles/config";

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;

  &.justify-start {
    align-items: flex-start;
  }

  &.justify-center {
    align-items: center;
  }

  &.striped-columns {
    table {
      tr {
        th {
          &:nth-child(even) {
            background-color: $white;
          }
        }

        td {
          &:nth-child(even) {
            background-color: $white;
          }
        }
      }
    }
  }

  &.striped-rows {
    table {
      tbody {
        tr:nth-child(odd) {
          th,
          td {
            background-color: rgba(0 0 0 / 2%);
          }
        }
      }
    }
  }
}
