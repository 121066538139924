@use "sass:color";

@import "../../../styles/config";
@import "../../../styles/mixins";

$pct-background-color: #fafafa;

.container {
  width: 100%;
  height: 100%;
  position: absolute;
  max-height: 100%;
}

.head {
  @include sticky-x;

  z-index: 2;
  border-bottom: 1px solid $color-table-border;
  margin: 0;
  top: 0; // This is necessary for the header to stick to the top
  background-color: $color-table-background-lighter;
  filter: drop-shadow(0 2px 2px $color-table-border);

  .row {
    th {
      &:first-child {
        @include sticky-x;

        background-color: $pct-background-color;
        border-right: 1px solid $color-table-border;
        filter: drop-shadow(2px 0 0 $color-table-border);
      }

      &:not:first-child {
        background-color: $color-table-background-lighter;
      }
    }
  }
}

.body {
  margin: 0;
  border-collapse: collapse;

  .row {
    margin: 0 auto;
    display: table-row;
    vertical-align: top;
    opacity: 1;

    .data {
      display: table-cell;
      text-align: center;
      vertical-align: top;
      z-index: 0;
      position: relative;

      &:first-child {
        @include sticky-x;

        background-color: $pct-background-color;
        border-right: 1px solid $color-table-border;
        filter: drop-shadow(2px 0 0 $color-table-border);
      }

      &:not:first-child {
        background-color: $color-table-background-lighter;
      }
    }
  }
}

.hide {
  opacity: 0;
  transition: all 0s linear;

  * {
    height: 0;
    max-height: 0;
    min-height: 0;
    padding: 0;
    margin: 0;
    visibility: hidden;
  }
}

.show {
  opacity: 1;
  transition: all 1s ease-in;
}
