@import "../../../styles/config";
@import "../../../styles/mixins";

.frame {
  padding-inline: 1rem;
  width: 100%;

  &.outdated {
    @include transition(opacity 0.5s);

    opacity: 0.3;
  }

  &.outdated:hover {
    opacity: 0.8;
  }

  .image {
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: scale-down;
    object-position: center;
    padding: 1rem;
    border: 1px solid $color-table-border-lightest;
    background-color: $white;
  }
}
