@import "../../../styles/config";
@import "../../../styles/mixins";

.container {
  max-height: 240px;
  flex-grow: 1;
  overflow: auto;

  @include scrollbar;

  @media (width >= 425px) {
    max-height: 360px;
  }

  @media (width >= 768px) {
    max-height: 400px;
  }

  @media (width >= 1024px) {
    max-height: 768px;
  }

  @media (width >= 1080px) {
    max-height: 960px;
  }
}
