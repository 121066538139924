@import "../../../../styles/config";

.container {
  display: flex;
  align-items: center;
  column-gap: 0.75rem;
  color: $color-text;
  font-family: $font-body;
  font-size: $font-xs;
  font-weight: $font-light;

  .flag {
    align-self: center;
  }

  .logo {
    max-width: 40px;
    max-height: 40px;
    object-fit: contain;
    object-position: center;
  }

  .name {
    flex-grow: 1;
    display: flex;
    align-self: center;
  }
}

.right {
  justify-content: flex-end;
  width: 100%;
}

.left {
  justify-content: flex-start;
}

.center {
  justify-content: center;
  width: 100%;
}
