@import "../../../styles/config";
@import "../../../styles/mixins";

.container {
  display: flex;
  height: 100%;
  justify-content: center;
  overflow-y: auto;
  width: 100%;

  @include scrollbar;
}

.table {
  border-collapse: collapse;
  border-spacing: 0;
  min-height: 100%;
  width: 100%;
  background-color: white;
  border: 1px solid rgba(0 0 0 / 8%);

  thead {
    tr {
      border-left: 0;
      border-right: 0;
      border-top: 0;
      border-bottom: 1px;
      border-style: solid;
      border-color: $smoke;
      box-shadow: $shadow-md-bottom;
    }
  }
}
