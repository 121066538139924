@import "mixins";

#dx-top-menu ul {
  margin: -1px 0 0 45px;
}

.dx-table-line > div:last-child {
  padding-right: 1.25em;
}

.dx-panel > div[role="button"] + div {
  margin: 0 25px !important;
  @include transition(margin 0.3s ease-out, height 0.3s ease-out);
}

.dx-panel.expanded > div[role="button"] + div {
  margin: 25px 25px 0 !important;
}

.dx-view {
  height: calc(100% - 4.5em);
  width: 100%;
}

.dx-button.dx-button-primary,
.dx-card .dx-button.dx-button-primary {
  color: #fff;
  background-color: #18a191;
}

.dx-area-bar {
  flex: 0 0 auto;
  padding: 1em 2.5em;
  text-align: left;
  border-bottom: 1px solid #d1d1d1;

  h1 {
    font-size: 1.3rem;
  }

  .dx-area-bar-filter-button {
    position: relative;
    top: 0;
  }
}

.dx-app-area {
  display: block;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  padding: 10px 16px 200px;
  line-height: 1.5;
  overflow-y: auto;
}

.dx-card.dx-actionable:hover {
  cursor: default;
}

.dx-reference-summary,
.dx-match-summary {
  .dx-reference-additional-info,
  .dx-match-additional-info,
  .dx-match-ref,
  .dx-reference-ref {
    font-size: 13px;
    color: #848484;
  }
}

#dx-top-bar h1 {
  cursor: default;
}

form .dx-form-group.dx-form-check input[type="checkbox"]::before {
  top: -0.4em;
  left: 0.2em;
}
