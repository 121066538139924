@import "../../../styles/_config";
@import "../../../styles/mixins";

.button {
  all: unset;
  cursor: pointer;
  border-radius: 0.25rem;

  svg {
    stroke: $color-text;
  }

  &:focus,
  &:focus-within {
    @include ring;
  }

  &[data-loading] {
    cursor: wait;
    opacity: 0.5;
  }

  &[data-disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &[data-state="on"] {
    color: $color-text-primary;

    svg {
      stroke: $primary;
      fill: $color-success-lighter;
    }
  }

  &[data-state="off"] {
    color: $color-text;
  }
}
