@import "./variables";
@import "../../../styles/config";

.container {
  align-items: center;
  align-self: center;
  background-color: rgb(255 255 255 / 100%);
  border-radius: 0.25rem;
  column-gap: 0.5rem;
  display: grid;
  filter: drop-shadow(1px 1px 3px $color-table-background-darker);
  grid-template-columns: 1fr 2fr;
  height: $table-cell-height;
  justify-content: start;
  margin: auto;
  width: calc(3 * #{$table-cell-width});
}
