@import "../../styles/_config";
@import "../../styles/_utilities";

.matches-overview-shop-matches-list-add-matches-btn,
.matches-overview-shop-matches-list-expand-matches-btn {
  border: none;
  background-color: transparent;
  height: min-content;
  width: 100%;
  padding: 0;
  transition: opacity 0.3s ease;
}

.matches-overview-shop-matches-list-add-matches-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-text-primary;
  font-size: 0.95rem;
  margin: 0.5rem auto;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.matches-overview-shop-matches-list-expand-matches-btn {
  &:hover {
    color: $color-text-primary;
    cursor: pointer;
    background-color: $color-base;
  }
}

.matches-overview-info-bar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .matches-overview-info-bar-settings-container {
    position: relative;
    margin-top: 2rem;

    button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      padding: 0.5rem;

      svg {
        margin-right: 0.5rem;
      }

      &:hover {
        color: $color-text-primary;
      }
    }

    .matches-overview-info-bar-settings-panel {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      background-color: $color-base;
      box-shadow: $shadow-sm;
      font-size: 0.85rem;
      min-width: 24rem;
      z-index: 0.8rem;

      .matches-overview-info-bar-settings-panel-row {
        width: 100%;
        height: auto;
        padding: 0.75rem 0.5rem;
      }

      button {
        background-color: $color-base;
        border: 1px solid $color-sidebar-lines;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(+50%, -50%);

        svg {
          margin-right: 0;
        }
      }
    }
  }
}
