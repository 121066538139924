@import "../../../styles/config";

.container {
  position: fixed;
  z-index: 10;

  .button {
    background-color: $black;
    color: $white;
    margin: 1rem;

    &.left {
      position: fixed;
      left: 32px;
      top: 35%;
    }

    &.right {
      position: fixed;
      right: 32px;
      top: 35%;
    }

    &:hover {
      background-color: rgba($black, 0.5);
    }
  }
}
