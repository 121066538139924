.dx-comparison-popup

  .dx-dialog-header
    text-align: right

    .close-button
      margin: 0

  .dx-comparison-popup-content
    padding: 0 !important

    .dx-comparison-table
      padding: 0 24px 24px 24px
      font-size: .9rem
      border-collapse: collapse

      .dx-comparison-row
        word-break: break-word
        // word-wrap: break-word
        hyphens: auto
        white-space: pre-line

        &.dx-compariton-row-title
          vertical-align: top

        .dx-column-attribute-name
          min-width: 120px
          max-width: 160px
          border-bottom: 1px solid #eee
          padding: 12px
          font-weight: bold

        .dx-column-value
          border-bottom: 1px solid #eee
          padding: 12px 0

          &.dx-image-column
            text-align: center
            padding: 0 12px
            height: 1px

          .dx-comparison-table-title-container
            font-size: 1.2rem
            .dx-comparison-table-subtitle
              font-weight: bold
              color: #888
            .dx-comparison-table-title
              font-weight: bold
              font-size: 1.5em

        img.dx-comparison-table-picture
          max-width: 100%
          max-height: 350px

        &:last-child, &.dx-image-row

          .dx-column-attribute-name, .dx-column-value
            border-bottom: none

        &.dx-comparison-row-centered
          .dx-column-attribute-name
            text-align: center

          .dx-column-1
            text-align: right
            .dx-image-zoom
              figure
                justify-content: end
          .dx-column-2
            text-align: left
            .dx-image-zoom
              figure
                justify-content: start
