@import "../../styles/_config";

.container {
  width: 6rem;
}

.flag {
  position: absolute;
  bottom: 0.25rem;
  right: 0.25rem;
  font-size: 1.33rem;
}

.logo {
  width: 100%;
  height: auto;
  object-fit: contain;
  object-position: center;
  padding: 1rem;
}

.name {
  font-size: 1.25rem;
  margin: 0 auto;
  padding: 1rem;
}
