@import "../../../../styles/config";

.items {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .item {
    flex-grow: 1;
    width: 280px;
    background-color: $white;
    border: 1px solid $smoke;
    border-radius: $rounded-sm;
    cursor: pointer;
    margin-block: 16px;
    padding-inline: 0.25rem;

    &:focus-visible,
    &:hover {
      background-color: rgba(0 0 0 / 4%);
      filter: saturate(80%) brightness(90%) contrast(111%); // filter to adjust the white border on images
    }

    .article {
      display: grid;
      place-items: center start;
      grid-template-columns: 1fr 2fr;
      column-gap: 0.5rem;
      align-self: center;

      .image {
        width: 80px;
        margin: 0 auto;
      }

      .data {
        display: grid;
        place-items: center start;
        row-gap: 0.125rem;
        color: $color-text-dark;
        font-family: $font-body;
        font-size: $font-xs;
        font-weight: $font-normal;
        text-align: left;
        text-overflow: ellipsis;

        h1,
        h3 {
          text-transform: capitalize;
        }

        h1 {
          font-weight: 500;
        }
      }

      .title {
        color: $color-text-light;
      }
    }

    .article-detail-trigger {
      width: 100%;
    }
  }
}
