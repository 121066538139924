@import "../../styles/_config";

.root {
  padding-block: 1rem;

  fieldset,
  legend {
    all: unset;
  }

  .fieldset {
    .legend {
      color: $color-text-dark;
      font-family: inherit;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: 2rem;
    }
  }
}
