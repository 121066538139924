@import "../../../styles/config";
@import "../../../styles/mixins";

.button {
  --this-background: var(--background, #{$color-base});
  --this-background-hover: var(--background-hover, #{$color-card-background-dark});
  --this-color: var(--color, #{$color-text-dark});
  --this-color-hover: var(--color-hover, #{$color-text-dark-contrast});

  align-items: center;
  background-color: var(--this-background);
  border-radius: 0.25rem;
  color: var(--this-color);
  column-gap: 0.5rem;
  cursor: pointer;
  display: flex;
  font-family: $font-body;
  font-weight: $font-medium;
  font-size: $font-sm;
  justify-content: center;
  padding-inline: 1rem;

  &:focus-visible {
    @include ring;
  }

  &:hover {
    background-color: var(--this-background-hover);
    color: var(--this-color-hover);
  }

  &[data-disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  span {
    > span {
      text-transform: lowercase;

      &::first-letter {
        text-transform: uppercase;
      }
    }

    > svg + span {
      margin: 0 0.5rem;
    }
  }

  /* -------------------------------------------------------------------------- */

  /*                                    color                                   */

  /* -------------------------------------------------------------------------- */

  &.neutral {
    --background: #{$color-base};
    --background-hover: #{$color-card-background-dark};
    --color: #{$color-text-dark};
    --color-hover: #{$color-text-dark-contrast};
  }

  &.danger {
    --background: #{$color-danger-lighter};
    --background-hover: #{$color-danger};
    --color: #{$color-danger-text};
    --color-hover: #{$color-danger-text-contrast};

    &.solid {
      --background: #{$color-danger};
    }
  }

  &.info {
    --background: #{$color-info-lighter};
    --background-hover: #{$color-info};
    --color: #{$color-info-text};
    --color-hover: #{$color-info-text-contrast};

    &.solid {
      --background: #{$color-info};
    }
  }

  &.success {
    --background: #{$color-success-lighter};
    --background-hover: #{$color-success};
    --color: #{$color-success-text};
    --color-hover: #{$color-success-text-contrast};

    &.solid {
      --background: #{$color-success};
    }
  }

  &.warn {
    --background: #{$color-warn-lighter};
    --background-hover: #{$color-warn};
    --color: #{$color-warn-text};
    --color-hover: #{$color-warn-text-contrast};

    &.solid {
      --background: #{$color-warn};
    }
  }

  &.secondary {
    --background: #{$secondary};
    --background-hover: #{$secondary-lighter};
    --color: #{$secondary-contrast};
    --color-hover: #{$secondary-contrast};
  }

  /* -------------------------------------------------------------------------- */

  /*                                   impact                                   */

  /* -------------------------------------------------------------------------- */

  &.solid {
    &:hover {
      filter: brightness(90%);
    }
  }

  &.outline {
    border: 1px solid $color-sidebar-lines;
    border-color: $color-sidebar-lines;
  }

  /* -------------------------------------------------------------------------- */

  /*                                size/padding                                */

  /* -------------------------------------------------------------------------- */

  &.regular {
    padding-block: 0.5rem;

    &.circle,
    &.squared {
      padding-inline: 0.5rem;
    }
  }

  &.short {
    padding-block: 0.375rem;

    &.circle,
    &.squared {
      padding-inline: 0.375rem;
    }
  }

  /* -------------------------------------------------------------------------- */

  /*                                    shape                                   */

  /* -------------------------------------------------------------------------- */

  &.circle {
    border-radius: 9999px;
  }
}
