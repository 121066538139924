@import "../../../../styles/config";

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  font-family: $font-body;
  font-size: $font-sm;
  font-weight: $font-light;
  padding-block: 0.5rem;
  padding-inline: 16px;
  width: 100%;

  .heading {
    text-transform: capitalize;
    position: sticky;
    top: calc(56px + 16px);
    align-self: flex-start;
  }
}
