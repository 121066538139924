@import "../../../styles/_config";

.container {
  display: flex;
  align-items: center;
  padding-block: 0.25rem;
  padding-inline: 0.25rem;

  .root {
    align-items: center;
    background-color: white;
    border: black;
    box-shadow: 0 0 2px $color-placeholder;
    display: flex;
    height: 1.25rem;
    justify-content: center;
    width: 1.25rem;

    &:hover {
      cursor: pointer;
      background-color: rgba($color-success-lighter, 0.25);
    }

    &:focus {
      color: $color-success;
      box-shadow: 0 0 0 2px $color-success-light;
    }

    .indicator {
      background-color: black;
      border-radius: 1px;
      color: white;
      height: 1rem;
      width: 1rem;

      &:hover {
        cursor: pointer;
        background-color: rgba($color-success-lighter, 0.5);
      }
    }
  }

  .label {
    color: $color-text-dark;
    font-family: inherit;
    font-size: 0.85rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    margin-left: 0.5rem;

    &:hover {
      cursor: pointer;
    }
  }
}
