@import "../../../../styles/_config";

.overlay {
  background-color: rgba($color-card-background-dark, 0.5);
  inset: 0;
  position: fixed;
  z-index: 10;
  isolation: isolate;
}

.content {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 0.25rem;
  box-shadow: $shadow-lg;
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 15;

  &.w-thin {
    max-width: 32.5rem;
  }

  &.w-normal {
    max-width: 40rem;
  }

  &.w-wide {
    max-width: 45rem;
  }

  &.w-widest {
    max-width: 80svw;
  }

  &.h-short {
    max-height: 60svh;
  }

  &.h-normal {
    max-height: 70svh;
  }

  &.h-tall {
    max-height: 80svh;
  }

  &.h-tallest {
    height: 90svh;
  }

  &:focus {
    outline: none;
  }
}
