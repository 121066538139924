.card-buttons
  display: inline-flex
  flex-wrap: wrap

  a
    margin-right: 1em
    margin-bottom: 1em

  a:last-of-type
    margin-right: 0
    margin-bottom: 0

.error-message-card
  .dx-card-content
    height: auto