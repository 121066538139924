@import "../../../../styles/_config";

.scroller {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  background-color: $white;
  color: $secondary-darker;
  cursor: default;
}
