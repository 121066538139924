
@import '../../styles/config'
@import '../../styles/mixins'
@import '../../styles/form'

.dx-table-line.match:hover, .match-item:hover
  .quality-indicator-root.editable
    .labels.not-set
      @include transition(opacity .5s)
      opacity: 1
    textarea.comment
      &.not-set
        @include transition(opacity .5s)
        opacity: 1

.quality-indicator-root
  display: flex
  width: 100%
  margin-top: 8px
  &.not-set
    @include transition(opacity .5s)

  &.editable
    .labels
      &.multi-select-container
        .multi-select
          &> div
            cursor: pointer
            &>div:hover
              background-color: rgba(0,0,0,0.05)
            &>svg
              visibility: visible

  .labels
    width: 160px
    min-width: 160px

    &.not-set
      opacity: 0

    .labels-label
      border: none
      display: flex
      justify-content: center

    &.multi-select-container
      .multi-select
        &> div
          &>div
            padding: 3px 14px
            background-color: transparent
            @include transition(background-color .5s)
            cursor: inherit
            &:after
              content: none !important
          &>svg
            visibility: hidden
    .labels-root
      border: none
      display: flex
      flex-direction: column
      align-items: center

      .dx-label
        margin-bottom: 2px
        text-align: center
        width: 6rem
        text-overflow: ellipsis

  .comment-container
    flex-grow: 1
    display: flex

    textarea.comment
      flex-grow: 1
      border-radius: 8px
      padding: 8px
      font-size: 10px
      border-color: #ddd
      font-family: inherit
      &.not-set
        opacity: 0
      &::placeholder
        color: rgba(0,0,0,0.3)
    div.comment
      flex-grow: 1
      padding: 0 8px 8px 8px
      font-size: 10px
      .label
        color: #b4b4b4
