@import "../../styles/_config";

.list {
  button {
    all: unset;
  }

  .item {
    border-radius: 0.25rem;
    cursor: pointer;

    &:hover,
    &:focus-within {
      background-color: rgba($color-sidebar-hover, 0.1);
    }
  }
}
