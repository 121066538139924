@font-face {
  font-family: Graphie;
  src: url("./fonts/Graphie/Graphie-Thin.otf") format("opentype");
  font-weight: 100;
}

@font-face {
  font-family: Graphie;
  src: url("./fonts/Graphie/Graphie-ExtraLight.otf") format("opentype");
  font-weight: 200;
}

@font-face {
  font-family: Graphie;
  src: url("./fonts/Graphie/Graphie-Light.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: Graphie;
  src: url("./fonts/Graphie/Graphie-Book.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: Graphie;
  src: url("./fonts/Graphie/Graphie-Regular.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: Graphie;
  src: url("./fonts/Graphie/Graphie-SemiBold.otf") format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: Graphie;
  src: url("./fonts/Graphie/Graphie-Bold.otf") format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: Graphie;
  src: url("./fonts/Graphie/Graphie-ExtraBold.otf") format("opentype");
  font-weight: 800;
}
