.dx-nutrients
  display: inline-block
  text-align: left

  .dx-nutrients-group
    padding: 0 0 0 0px
    line-height: 1.5em
    list-style-type: none

    .dx-nutrients-group-item

      .dx-nutrients-group-title
        font-weight: bold

      .dx-nutrients-no-padding
        padding: 0 !important

      .dx-nutrients-group-list
        padding: 0 0 0 16px
        list-style-type: ""

        .dx-nutrients-group-list-item

          .dx-nutrients-group-list-item-label
            color: #999
