@import "./variables";

.container {
  $radius: 1rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  font-weight: 600;
  color: #fff;

  /* This bg color blends with childrens' backgrounds to prevent translucid aspect */
  background-color: #fff;
  border-radius: $radius;

  > * {
    padding: 0.2rem 0.4rem;
  }

  > :only-child {
    min-width: 2.25rem;
  }

  > :first-child {
    border-top-left-radius: $radius;
    border-bottom-left-radius: $radius;
  }

  > :last-child {
    border-top-right-radius: $radius;
    border-bottom-right-radius: $radius;
  }

  > :first-child:last-child {
    border-top-right-radius: $radius;
    border-bottom-right-radius: $radius;
  }
}
