@import "../../../styles/config";

.app-area {
  display: block;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  padding: 2.5em;
  line-height: 1.5;
  overflow-y: auto;
}
