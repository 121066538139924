.material-symbols-outlined {
  font-variation-settings:
    "FILL" 0,
    "wght" 200,
    "GRAD" 0,
    "opsz" 24;
}

.fallback-icon {
  filter: invert(86%) sepia(99%) saturate(2%) hue-rotate(112deg) brightness(220%)
    contrast(101%);
  height: 24px;
  padding: 3px;
  width: 24px;
}

.menu-icon {
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%)
    contrast(100%);
  opacity: 0.54;
}
