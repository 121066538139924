
@import '../../styles/config'
@import '../../styles/mixins'
@import '../../styles/form'

.factor-dialog
  .factor-input
    width: 70%
    min-width: 6.4em
    font-size: 1em
    font-weight: 600
  .factor-calc-input
    width: 85%
    font-weight: 100
    font-size: 1em


  .label
    color: $text-color-grey

  .factor-adusting-area
    padding-right: 4em

  .picture
    min-width: 10em
    min-height: 10em
    background-repeat: no-repeat
    background-size: contain
    margin-bottom: 1em
    width: 12em
    height: 12em
    object-fit: contain


  .product-name
    font-size: 1.3em
    font-weight: 500
    margin-bottom: .8rem

    a, .link-button
      text-decoration: none
      color: $text-color-black
      @include transition(color .5s)

    a:hover, .link-button:hover
      color: $text-color-grey

  .price
    display: flex
    flex-direction: column
    .main
      font-size: 1.2rem
      font-weight: 500
    .normalized
      font-size: .9rem
      margin-left: .5em
      color: $text-color-grey


  .result
    padding-top: .4rem
    font-size: 1.3em
    font-weight: 500

  .brand
    color: $text-color-grey
    font-size: .7rem
    .brand-icon
      margin-right: .3em
      margin-bottom: -.1em

  .product
    align-items: center

  .read-only-field
    @include transition(opacity, .5s)
    @include transition(text-decoration, .5s)
    @include transition(color, .5s)

    flex-direction: row
    align-items: center

    .label
      font-size: 1.2em
    .value
      font-size: 1rem
      min-width: 3em
    .normalized
      font-size: .9rem
      margin-left: .5em
      font-weight: 300
      color: $text-color-grey

  .overwritten
    color: $text-color-grey
    text-decoration: line-through

  .read-only-field.overwritten
    .value
      color: $text-color-grey
      text-decoration: line-through

  .calculator
    width: 100%
    border: none
    box-shadow: none
    background-color: #F5F5F5
    margin-bottom: 1em

    .calc-fields-area
      display: flex
      align-items: flex-end
      text-align: right
      justify-content: space-around
      padding-top: .4rem
      font-size: 1.3em
      font-weight: 500

      .form-control
        width: 100%

        label
          transform-origin: top right
          left: auto
          right: .8em

        input
          text-align: right

      .mult-symbol
        color: $text-color-grey
        font-weight: normal
        margin: 0 .5em

    .expand-icon
      font-size: 1.3em
      margin-bottom: -.3em

    .header
      min-height: 3em
      width: 100%
      .content
        margin: 0 0
        justify-content: center
        .title
          padding: 0
          line-height: 2rem
          color: $text-color-grey
          font-size: .8rem
    .calc-expanded
      .expand-icon
          transform: rotate(180deg)
  .calculated-factor
    margin-top: 2rem
    .value
      font-weight: bold
      font-size: 1.1em
      margin-left: .4em
    .use-this-link
      margin-left: .4em
      cursor: pointer

    button
      margin-left: .5em
      text-transform: none
      height: 1.8em
      margin-top: -.2em
      padding-top: .2rem
      line-height: 1em
      font-size: 1em

    .use-this-link:hover
      text-decoration: underline

  .actions
    padding: 1em
    button
      min-width: 9em
      svg
        margin-right: .4em

  .currency
    margin-right: .1em

.factor-button
  background-color: white

.button-fheight
  button
    height: 100%
    padding-left: 20px
    padding-right: 16px
