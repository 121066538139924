@import '../../styles/config'
@import '../../styles/mixins'
@import '../../styles/form'

.product-card

  height: auto !important
  margin-top: 8px

  .header
    text-align: center

  .title
    font-weight: 400
    font-size: 1.8em

    a
      text-decoration: none
      color: $text-color-black
      @include transition(color .5s)

    a:hover
      color: $text-color-grey

    .brand
      font-size: .9rem
      color: $text-color-grey
      margin-left: -1em
      .brand-icon
        margin-right: .3em
        margin-bottom: -.1em

  .subheader
    font-size: .9rem
    line-height: 1.2em
    color: $text-color-grey

  .picture
    display: flex
    flex-direction: column
    justify-content: center
    align-items: flex-start
    width: 100%
    min-height: 100px
    @include transition(background-image .5s)
    @media(orientation: portrait)
      min-height: 300px

    background-size: contain
    margin-bottom: 16px

  .listings
    display: flex
    justify-content: center
    margin-bottom: 1em
    .listing
      display: flex
      align-items: center
      flex-direction: column
      padding: 0 1em
      .shop
        color: $text-color-grey
        .country
          text-transform: uppercase

  .price
    font-weight: 500

  .normalized-price
    display: block
    color: $text-color-grey
    font-size: .6em
    font-weight: 300
    margin-left:  .4rem
    margin-bottom: .4rem

  .ean-container
    padding: 0 .5rem

    display: flex
    justify-content: space-evenly
    color: $primary

    .ean-icon
      margin-top: -.1em
      margin-right: .2em

    .ean-badge, .match-badge, .review-badge
      display: inline-flex
      margin: 0 .5em 0 0

    .ean-value
      display: inline-block
      font-size: 1.4em
      font-weight: 500
      color: $primary
      margin-bottom: .8rem

    .barcode
      display: inline-block
      margin-top: .5em
      svg
        padding: .3em .5em
        height: 5em
        background-color: #f5f5f5

    .article-nr
      font-size: 13px
      color: #848484
      // font-size: 1.4em
      // font-weight: 500
      display: flex
      justify-content: center
      // margin-left: 1.5rem
      margin-bottom: .5rem
      // color: $primary
      .value
        margin-left: .2rem

  .ingredients
    font-size: 1em
    background-color: #eee
    padding: .5rem
    text-align: justify
    color: $text-color-grey

    .label
      color: $text-color-black
      font-weight: 500

  .description
    padding: 0 .5rem
    margin-bottom: .5rem
    font-size: 1em
    text-align: justify
