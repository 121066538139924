@import "../../styles/_config";
@import "../../styles/_utilities";

.brand {
  color: $text-color-grey;
  font-weight: 600;
  font-size: 0.8rem;
}

.container {
  display: grid;
  position: relative;
  font-family: Roboto, sans-serif;
  text-align: left;

  > * {
    margin: 0.1rem;
    padding: 2px 8px;
    text-wrap: balance;
  }
}

.controls-container {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  justify-items: start;
}

.product-name {
  color: $color-text;
  font-weight: 600;
  font-size: 0.8rem;
}

.product-pricing {
  color: $text-color-grey;
  font-size: 0.7rem;
}
