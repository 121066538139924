@import "../../styles/_config";

.header {
  display: grid;
  place-items: center start;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  column-gap: 0.25rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  margin-top: 1rem;

  .subtitle {
    color: $color-text-light;
  }
}
