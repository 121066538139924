.ean-list-root
  font-size: 13px
  color: #848484

  .dx-button
    background-color: #ededed

  .dx-button-xs
    top: 0
    height: auto
    margin-left: 10px
    padding: 1px 10px 2px
    vertical-align: baseline

  .ean-list-items
    display: flex
    flex-direction: column

    .dx-additional-ean
      padding-left: 31px
