@import "../../../styles/config";

.header {
  .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 0.9rem;
    font-weight: 400;
    width: 22rem;
    word-break: break-word;
  }
}
