@import "../../../../styles/_config";
@import "../../../../styles/mixins";

.body {
  position: relative;
  flex: 1 1 auto;
  overflow-y: auto;
  scroll-padding: 0;

  @include scrollbar;

  > * + * {
    padding-top: 1rem;
    padding-bottom: 1.5rem;

    &:not(:last-child, :first-child) {
      border-bottom: 1px solid rgba($color-sidebar-lines, 0.25);
    }
  }

  // @see https://d29mtkonxnc5fw.cloudfront.net/site_assets/diy-frames-whats-included.jpg
  &.window-mat {
    padding-inline: 1.5rem;
  }

  &.window-frame {
    border-top: 1px solid $color-dialog-border-lighter;
    border-bottom: 1px solid $color-dialog-border-lighter;
  }
}
