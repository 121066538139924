.dx-match-source-chip-label
  display: inline-flex
  height: 16px !important
  padding: 8px !important
  font-size: 11px !important
  border-radius: 8px
  align-items: center
  justify-content: center
  background-color: rgba(0, 0, 0, 0.08)
  margin-right: 2px
  min-width: 50px
