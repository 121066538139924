@import "../../../styles/_config";
@import "../../../styles/mixins";

.group {
  display: inline-flex;
  background-color: rgba($secondary, 0.1);
  border: 1px solid $secondary;
  border-radius: 0.125rem;
  box-shadow: $shadow-sm;
  user-select: none;

  &:focus,
  &:focus-within {
    @include ring;

    box-shadow: $shadow-sm;
  }
}

.item {
  align-items: center;
  background-color: $white;
  color: $color-text-secondary;
  cursor: pointer;
  display: flex;
  font-size: $font-md;
  height: 1.75rem;
  justify-content: center;
  line-height: 1;
  margin-left: 1px;
  min-width: 3rem;

  &[data-state="on"] {
    background-color: #635794;
    color: $color-text-secondary;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:hover {
    background-color: $secondary-darker;
    color: $accent-contrast;
  }

  &:focus-visible {
    position: relative;
    box-shadow: 0 0 0 2px rgba($secondary, 0.4);
  }
}
