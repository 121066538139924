@import "./variables";

.container {
  align-items: center;
  aspect-ratio: 1 / 1;
  background-color: transparent;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  margin: 0.8rem auto;
  max-height: 6rem;
  overflow: hidden;
}

.image {
  height: 100%;
  object-fit: contain;
  object-position: center;
  width: 100%;
}
