@import "_config";

.form {
  margin: 16px;

  .message {
    display: inline-block;
    margin-top: 0.5em;
    font-size: 1.3em;
  }

  .error-area {
    border: none;
    box-shadow: none;
  }

  .error-area::before {
    content: none;
  }

  .message.error {
    color: $color-danger;
  }
}
