@import "../../../../styles/config";

.heading {
  color: $black;
  font-family: $font-body;
  font-size: $font-sm;
  font-weight: $font-medium;
  display: flex;
  width: 90%;
}

.detail {
  margin-top: 1rem;
  display: grid;
  justify-items: center;
  align-content: start;
  grid-auto-rows: auto;
  grid-template-columns: 3fr 1fr;
  column-gap: 0.5rem;
  position: static;
  background-color: $white;

  .logo {
    display: inline;
    width: 1.25rem;
    height: auto;
    object-fit: contain;
    object-position: center;
  }

  .mainContainer {
    display: flex;
    flex-direction: column;
    width: 100%;

    .main {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: sticky;
      top: 0;

      .image {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: transparent;

        // flex-grow: 1;
      }

      > * + * {
        margin-top: 0.75rem;
      }

      .data {
        background-color: $smoke;
        border-radius: $rounded;
        padding: 0.75rem;

        > * + * {
          margin-top: 0.5rem;
        }
      }
    }
  }

  .aside {
    border-left: 1px solid $smoke;
    padding-left: 20px;
    width: 100%;

    .main {
      background-color: $white;
      padding-bottom: 16px;
      padding-top: 16px;
    }

    .data {
      overflow-y: auto;
      height: 50vh;
      padding-bottom: 16px;
      padding-top: 16px;

      > * + * {
        margin-top: 0.75rem;
      }
    }
  }
}
