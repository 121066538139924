@import "config";

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin transform($property) {
  -webkit-transform: $property;
  -moz-transform: $property;
  -ms-transform: $property;
  transform: $property;
}

@mixin animation($property) {
  -webkit-animation: $property;
  -moz-animation: $property;
  -ms-animation: $property;
  animation: $property;
}

@mixin animation-delay($property) {
  -webkit-animation-delay: $property;
  -moz-animation-delay: $property;
  -ms-animation-delay: $property;
  animation-delay: $property;
}

@mixin transition($property...) {
  -webkit-transition: $property;
  -moz-transition: $property;
  -ms-transition: $property;
  transition: $property;
}

@mixin ring($ocolor: $color-info-lighter, $ooffset: 2px, $owidth: 1px) {
  outline: $ocolor solid $owidth;
  outline-offset: $ooffset;
}

@mixin scrollbar() {
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    all: unset;
  }

  &::-webkit-scrollbar {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    width: $scroll-width;
    height: $scroll-width;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-sidebar-hover;
    border-radius: $scroll-radius;
    width: $scroll-width;

    &:hover {
      cursor: grab;
      background-color: rgba(82 68 136 / 100%); // $primary;
    }

    &:active {
      background-color: rgba(82 68 136 / 100%); // $primary;
    }
  }

  &::-webkit-scrollbar-track {
    width: 0.25rem;
  }
}

@mixin sticky-x($position: sticky, $z-index: 1) {
  left: 0;
  position: $position;
  z-index: $z-index;
}

@mixin sticky-x-backdrop($blur: 2px, $bg-color: $color-base) {
  backdrop-filter: blur($blur);
  background-color: rgba($bg-color, 0.4);
}

@mixin sticky-y($position: sticky, $z-index: 2) {
  top: 0;
  position: $position;
  z-index: $z-index;
}

@mixin sticky-y-backdrop($bg-blur: 4px, $bg-color: $color-base) {
  backdrop-filter: blur($bg-blur);
  background-color: rgba($bg-color, 0.8);
}
