@import "_config";

.flex {
  display: flex;
}

.flex.h {
  flex-direction: row;
}

.flex.v {
  flex-direction: column;
}

.flex.center {
  justify-content: center;
  align-items: center;
}

.grow {
  flex-grow: 1;
}

.error {
  color: $color-danger !important;
}

.snackbar.success {
  background-color: $primary;
}

.button {
  box-shadow: none;
}

button.notification-action {
  color: white;
  border-color: white;
}

.button.danger,
.dx-button.danger {
  background-color: $color-danger;
  color: white;
}

img.icon {
  height: 24px;
  width: 24px;
}

.text-right {
  text-align: right;
}

html {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

h1 {
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-family: Roboto, sans-serif;
  font-weight: 100;
  font-size: 28px;
  line-height: 1;
}

.dialog {
  .title h6 {
    font-weight: 300 !important;
    padding-bottom: 0.5em;
    border-bottom: 1px solid #ccc;
  }
}

.no-opacity {
  opacity: 0;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

.scrollable-y {
  overflow-y: auto;
}
