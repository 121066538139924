@import "../../../../styles/config";
@import "../../../../styles/mixins";

.paper {
  min-width: 250px;

  // margin-top: 45px; // toolbar height
  // padding-bottom: 45px; // the top margin

  @include scrollbar;
}

.header {
  display: flex;
  justify-content: space-between;
}

.title {
  display: flex;
  align-items: center;
}
