@import "../../../../styles/config";
@import "../../../../styles/mixins";

.container {
  font-size: $font-sm;
  font-weight: $font-normal;
  font-family: $font-body;

  .title {
    color: rgba(0 0 0 / 60%);
    display: block;
    text-transform: capitalize;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 0.125rem;
    column-gap: 0.25rem;

    .value {
      color: rgba(0 0 0 / 87%);
      display: inline-block;
      text-transform: capitalize;
    }

    .debug {
      @include scrollbar;

      text-transform: none;
      overflow: scroll;
      width: 145px;
    }
  }
}
