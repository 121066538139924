@import "../../styles/_config";

.control {
  display: flex;
  align-items: center;
  flex: 0 0 auto; /* Keep the original width for non-growing elements */
  border-right: 1px solid $color-sidebar-lines;
  height: 100%;
  padding-left: 1rem;
  padding-right: 1rem;

  .center {
    justify-content: center;
  }

  .end {
    justify-content: flex-end;
  }

  .start {
    justify-content: flex-start;
  }
}

.slot-start {
  margin-right: 0.5rem;
}

.slot-end {
  margin-left: 0.5rem;
}

.grow {
  flex: 1;
  flex-basis: 0;
}
