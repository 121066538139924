@import "../../../../styles/config";

.checked {
  color: $secondary-darker !important;
}

.no-gutters {
  padding: 0;
}

.checkbox-gutter {
  padding: 0.25rem 0.5rem 0.25rem 1rem;
}

.text-gutter {
  padding: 0 0.25rem;
}
