@import "../../../styles/config";

.container {
  --this-background: var(--background, #{$black});
  --this-color: var(--color, #{$white});

  border-radius: $rounded-full;
  background-color: var(--this-background);
  color: var(--this-color);
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;

  /* -------------------------------------------------------------------------- */

  /*                                  absolute                                  */

  /* -------------------------------------------------------------------------- */
  &.absolute {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(+40%, -40%);
  }

  /* -------------------------------------------------------------------------- */

  /*                                    color                                   */

  /* -------------------------------------------------------------------------- */

  &.black {
    --background: #{$black};
    --color: #{$white};
  }

  &.primary {
    --background: #{$primary};
    --color: #{$primary-contrast};
  }

  &.secondary {
    --background: #{$secondary};
    --color: #{$secondary-contrast};
  }

  /* -------------------------------------------------------------------------- */

  /*                                    size                                    */

  /* -------------------------------------------------------------------------- */
  &.small {
    font-size: $font-xxs;
  }

  &.medium {
    font-size: $font-xs;
  }

  &.large {
    font-size: $font-sm;
  }
}
