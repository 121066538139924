@import "../../styles/_config";
@import "../../styles/_utilities";

.toolbar-root {
  background-color: $white;
  display: flex;
  flex-direction: column;
}

.control-list {
  align-items: center;
  border-radius: 4px;
  color: $color-text-dark;
  display: flex;
  font-size: 0.8rem;
  font-weight: 600;
  justify-content: center;
  overflow-y: auto;
}
