@use "sass:color";

// BREAKPOINTS
// ----------------------------------------------------------------------

$xs: 0;
$sm: 600px;
$md: 960px;
$lg: 1280px;
$xl: 1920px;

// COLORS
// ----------------------------------------------------------------------
// tokens (taken from Cheatsheet Daltix Colours on Google Drive)
$black: #000;
$blue: #0f80fa;
$green: #029678;
$grey: #424242;
$orange: #f49037;
$purple: #524488;
$red: #d12d1a;
$smoke: #f5f5f5;
$stone: #aaa;
$topaz: #0ab9a6;
$white: #fff;
$yellow: #ffea00;

// semantic tokens
$primary: $topaz;
$primary-contrast: $white;
$secondary: $purple;
$secondary-darker: color.scale($secondary, $lightness: - 25%);
$secondary-contrast: $white;
$secondary-lighter: color.scale($secondary, $lightness: + 50%);
$accent: $orange;
$accent-contrast: $white;
$color-base: $smoke;
$color-border: $smoke;
$color-dialog-border: $stone;
$color-dialog-border-lighter: color.adjust($stone, $alpha: - 0.25);
$color-dialog-border-lightest: color.adjust($stone, $alpha: - 0.75);
$color-input-background: $smoke;
$color-table-background-darker: $stone;
$color-table-background: $smoke;
$color-table-background-lighter: $white;
$color-table-border: #eaeaea;
$color-table-border-lighter: color.adjust($stone, $alpha: - 0.25);
$color-table-border-lightest: color.adjust($stone, $alpha: - 0.75);

// text
$color-text-primary: $topaz;
$color-text-secondary: $purple;
$color-text-accent: $orange;
$color-text-light: $stone;
$color-text: $grey;
$color-text-dark: #333;
$color-text-dark-contrast: $white;

// success
$color-success-lighter: #a2feef;
$color-success-light: #00c6ac;
$color-success: #0fb1a2;
$color-success-text: $color-text-dark;
$color-success-text-contrast: $color-text-dark-contrast;

// danger
$color-danger-lighter: #f9d1d9;
$color-danger-light: #f97575;
$color-danger: #d12d1a;
$color-danger-text: $color-text-dark;
$color-danger-text-contrast: $color-text-dark-contrast;

// info
$color-info-lighter: #a9c6e6;
$color-info-light: #52a2f8;
$color-info: $blue;
$color-info-text: $color-text-dark;
$color-info-text-contrast: $color-text-dark-contrast;

// warn
$color-warn-lighter: #fffdd0;
$color-warn-light: #fff25d;
$color-warn: #f59c00;
$color-warn-text: $color-text-dark;
$color-warn-text-contrast: $color-text-dark;

// miscellaneous
$color-sidebar-text: $color-text;
$color-sidebar: #dcdcdc;
$color-sidebar-hover: #bbb;
$color-sidebar-lines: #c7c7c7;
$color-card-background: $white;
$color-card-background-dark: #555;
$color-form-line: rgba(#000, 0.1);
$color-line: rgba(#000, 0.1);
$color-placeholder: rgba(#000, 0.3);
$ean-color: #00b627;
$manual-color: #f0b627;
$migrated-color: #aab688;
$suggestion-color: #aab6dd;
$country-match-color: #11c684;
$shop-match-color: #00da27;
$text-color-grey: #999;
$text-color-black: #000;

// BORDERS
// ______________________________________________________________________
$rounded-none: 0;
$rounded-xs: 0.0675rem;
$rounded-sm: 0.125rem;
$rounded: 0.25rem;
$rounded-md: 0.375rem;
$rounded-lg: 0.5rem;
$rounded-xl: 0.75rem;
$rounded-2xl: 1rem;
$rounded-3xl: 1.5rem;
$rounded-full: 9999px;

// FONTS
// ______________________________________________________________________
// font-family
$font-header: roboto, sans-serif;
$font-body: -apple-system, blinkmacsystemfont, "Segoe UI", roboto, oxygen, ubuntu,
  cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

// font-weight
$font-thin: 100;
$font-lighter: 200;
$font-light: 300;
$font-normal: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;
$font-bolder: 800;
$font-thick: 900;

// font-size
$font-xxs: 0.625rem;
$font-xs: 0.75rem;
$font-sm: 0.875rem;
$font-md: 1rem;
$font-lg: 1.125rem;
$font-xl: 1.25rem;
$font-xxl: 1.5rem;
$font-3xl: 1.875rem;
$font-4xl: 2.25rem;
$font-5xl: 3rem;
$font-6xl: 3.75rem;
$font-7xl: 4.5rem;
$font-8xl: 6rem;
$font-9xl: 8rem;

// SHADOWS <offset-x> <offset-y> <blur-radius> <spread-radius> <color>
// ----------------------------------------------------------------------

$shadow-xs: 0 1px 2px 0 rgb(0 0 0 / 5%);
$shadow-sm:
  0 1px 3px 0 rgb(0 0 0 / 10%),
  0 1px 2px -1px rgb(0 0 0 / 10%);
$shadow-sm-bottom: 0 2px 2px -1px rgb(0 0 0 / 10%);
$shadow-md:
  0 4px 6px -1px rgb(0 0 0 / 10%),
  0 2px 4px -2px rgb(0 0 0 / 10%);
$shadow-md-bottom: 0 4px 2px -2px rgb(0 0 0 / 10%);
$shadow-lg:
  0 10px 15px -3px rgb(0 0 0 / 10%),
  0 4px 6px -4px rgb(0 0 0 / 10%);
$shadow-lg-bottom: 0 6px 4px -4px rgb(0 0 0 / 10%);
$shadow-xl:
  0 20px 25px -5px rgb(0 0 0 / 10%),
  0 8px 10px -6px rgb(0 0 0 / 10%);
$shadow-xl-bottom: 0 8px 8px -6px rgb(0 0 0 / 10%);
$shadow-2xl: 0 25px 50px -12px rgb(0 0 0 / 25%);
$shadow-2xl-bottom: 0 16px 16px -12px rgb(0 0 0 / 25%);
$shadow-inner: inset 0 2px 4px 0 rgb(0 0 0 / 5%);

// DIMENSIONS
// ----------------------------------------------------------------------

$header-height: 50px;
$breakpoint-lg: 800px;
$border-radius: 3px;
$element-shadow: 0 1px 2px rgba(#000, 0.3);
$element-spacing-vertical: 7px;
$element-spacing-horizontal: 8px;
$transition: all 0.3s;
$scroll-width: 8px;
$scroll-radius: 6px;
