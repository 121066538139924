@import "../../../styles/config";
@import "../../../styles/mixins";

.footer {
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  font-weight: 400;
  justify-content: flex-start;
  margin-top: 0.5rem;
  text-align: left;
  width: 100%;
  padding-inline: 0;

  &.outdated {
    @include transition(opacity 0.5s);

    opacity: 0.3;
  }

  &.outdated:hover {
    opacity: 0.8;
  }

  > * {
    padding-inline: 1rem;
  }

  .heading {
    border-bottom: 1px solid $color-table-border-lightest;
    min-height: 1.5rem;
    padding-block: 0.125rem;
    user-select: none;
    width: 100%;
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 0.5rem;
    min-height: 2.75rem;
    color: $black;
  }
}
