@import '../../styles/config'
@import '../../styles/_mixins.scss'

.existing-matches-panel

  .matches-list
    width: 100%

  .list-title
    & > span.title-icon
      display: inline-block
      margin-left: .2em
      margin-right: .5em
      font-size: 1.1em

  .expansion-panel-subtitle:hover
    opacity: 1
  .expansion-panel-subtitle
    color: $primary
    font-size: 1em
    opacity: .5
    @include transition(opacity .5s)
    & > span
      margin-left: 2em
    .expansion-panel-icon
      margin-bottom: -.12em
      margin-left: .2em
