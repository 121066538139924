.redirection-info
  display: flex
  flex-direction: column
  align-items: center
  // justify-content: center
  height: 100%
  background-color: white
  font-size: 2rem

  img
    height: 150px

  .message
    margin-top: 32px
    min-height: 200px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    border: 1px solid #ddd
    padding: 32px
    width: 80%

    a
      margin: 24px
      font-size: 3.5rem
  .auto-redirect-message
    margin-top: 32px
    font-size: 1rem