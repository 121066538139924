@import "../../../../styles/_config";

.item {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 3px;
  color: $color-text-dark;
  font-size: $font-sm;
  line-height: 1;
  height: 1.5rem;
  padding-inline: 0.25rem;
  user-select: none;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &[data-disabled] {
    color: $smoke;
    pointer-events: none;
  }

  &[data-highlighted] {
    background-color: $smoke;
    cursor: pointer;
    outline: none;
  }

  .indicator {
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    right: 0;
    width: 1.5rem;
  }
}
