@import "../../../../styles/_config";

.container {
  display: grid;
  place-items: center center;
  padding-inline: 1rem;

  .image {
    margin-block: 2rem;
    aspect-ratio: 1 / 1;
    max-width: 20rem;
    min-height: 20rem;
  }

  .message {
    color: $color-text-accent;
    font-size: 2rem;
    font-weight: 300;
    line-height: 2.25rem;
    padding-bottom: 2rem;
    text-align: center;
    text-wrap: balance;
  }
}
