@import "./variables";

.container {
  flex-direction: row;
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  background-color: #fff;
  border: 1px solid #c3c3c3;
  border-radius: 0.33rem;
  text-align: center;
  min-width: 3.5rem;
  max-width: 90%;
}

.price {
  font-family: Roboto, sans-serif;
  font-size: 0.85rem;
  margin: 0.5rem 0;

  &:last-child {
    margin: 0.4rem;
  }

  &:first-child:nth-last-child(2):not(:only-child) {
    color: #5e5e5e;
    font-size: 0.7rem;
    margin-left: 0.4rem;
  }
}
