@import "../../../../styles/_config";

.content {
  background-color: $white;
  border-radius: $rounded;
  border: 1px solid $stone;
  box-shadow: $shadow-sm;
  min-width: 10rem;
  overflow: hidden;
  z-index: 5;
}
