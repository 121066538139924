@import "mixins";

.content-in {
  opacity: 0;

  @include animation(content-in 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards);
}

@include keyframes(content-in) {
  0% {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

.fade-in {
  opacity: 0;

  @include animation(fade-in 1s forwards);
}

.fade-out {
  opacity: 1;

  @include animation(fade-in 0.5s reverse);
}

@include keyframes(fade-in) {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.slide-left {
  opacity: 0;
  transform: translateX(1000px);
  @include animation(slide-left 1s forwards);
}

@include keyframes(slide-left) {
  0% {
    opacity: 0;
    transform: translateX(1000px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.slide-right {
  opacity: 0;
  transform: translateX(-1000px);
  @include animation(slide-right 1s forwards);
}

@include keyframes(slide-right) {
  0% {
    opacity: 0;
    transform: translateX(-1000px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.table-cell {
  background-color: #fafaee;
  @include animation(table-cell 2s forwards);
}

@include keyframes(table-cell) {
  0% {
    background-color: #fafaee;
  }

  100% {
    background-color: transparent;
  }
}
