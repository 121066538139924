.category-card {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-right: 20px;
  min-height: 208px;
  width: 320px;
}

.card-link {
  align-items: center;
  text-decoration: none;

  &:hover {
    text-decoration: none !important;
    background-color: rgba(82 68 136 / 4%) !important;
  }
}
