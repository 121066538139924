@import '../../styles/config'
@import '../../styles/mixins'
@import '../../styles/form'
  
.search-panel
  width: 100%
  margin-top: 1em
  text-align: center
  // padding: 2em
  .search-field
    width: 90%
    .input-button
      padding: 3px
  .result-count
    display: inline-block
    margin-top: .7em
    color: #888
    font-size: 1.1em

  .search-results

    margin-top: 2em

    &.suggested-matches-list
      // height: calc(100vh - 27rem)
      // height: calc(100vh - 28rem)
      height: calc(100vh - 25.5rem)

    &.fast-search-container

      .fast-search
        color: #888
        display: inline-block
        margin: 0 1em
        padding: .5em 2em
        cursor: pointer
        font-size: 1.3em
        border-radius: 1em
        @include transition(background-color .5s)
        @include transition(color .8s)
      .fast-search:hover
        color: #666
        background-color: #F4FFF6
        text-decoration: underline
