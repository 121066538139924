@import '../../styles/config'
@import '../../styles/_mixins.scss'

.filter-item

  .counters:hover
    opacity: 1
  .counters
    font-size: .9em
    margin-left: 1em
    color: $primary
    opacity: .5
    @include transition(opacity .5s)

    & > span

      & > span.material-icons
        margin-right: 1em
        margin-bottom: -.05em
        margin-left: .2em
      svg
        margin-right: 1em
        margin-bottom: -.2em

.existing-matches-panel
  text-align: center
  margin-bottom: 8px

  .existing-matches-content
    padding-bottom: 0

    .result-count
      display: inline-block
      margin-top: .7em
      color: #888
      font-size: 1.1em