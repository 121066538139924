ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

button,
h1,
h2,
h3,
h4,
h5,
h6,
input,
p {
  all: unset;
}
