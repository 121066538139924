.container {
  align-items: center;
  aspect-ratio: 1 / 1;
  background-color: transparent;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  margin: 0.8rem auto;
  max-height: 600px;
  overflow: hidden;
}

.image {
  height: 100%;
  object-fit: contain;
  object-position: center;
  width: 100%;
}

.image-zoom {
  height: 100%;
  width: 100%;

  figure {
    background-repeat: no-repeat;
    background-size: 0;
    margin: 0;
    height: 100%;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: zoom-in;
  }

  img {
    display: block;
    pointer-events: none;
  }
}

.enabled {
  figure {
    cursor: zoom-out;

    &:hover {
      background-size: auto 1900px;

      img {
        opacity: 0;
      }
    }
  }
}
