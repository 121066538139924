@import "../../styles/config";
@import "../../styles/mixins";

.container {
  overflow-y: auto;
  height: 100vh;
  display: flex;

  @include scrollbar;

  .matches-overview-table-cell-link {
    margin-bottom: 1rem;
  }

  .matches-overview-table-cell-empty {
    display: flex;
    align-items: flex-end;
    min-height: inherit;
  }

  .table {
    max-width: 98svw;
  }
}

.no-reference-products-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;
}

.no-reference-products-message {
  color: $color-text-accent;
  font-size: 2rem;
  font-weight: 400;
  margin-top: 2rem;
  margin-bottom: 1rem;
  line-height: 2.25rem;
  text-align: center;
  text-wrap: balance;
}
