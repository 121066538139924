.dx-image-zoom
  height: 100%
  width: 100%
  
  figure
    background-repeat: no-repeat
    background-size: 0
    margin: 0
    height: 100%
    flex-grow: 1
    display: flex
    justify-content: center
    align-items: center
    cursor: zoom-in

  &.enabled
    figure
      cursor: zoom-out
      &:hover
        background-size: auto 1900px
        img
          opacity: 0

  img
    display: block
    pointer-events: none
