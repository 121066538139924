@import "../../styles/_config";
@import "../../styles/_utilities";

.toolbar-root {
  background-color: $color-base;
}

.control-list {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0 auto;
  border-bottom: 1px solid $color-sidebar-lines;
  border-top: 1px solid $color-sidebar-lines;
  list-style: none;
  list-style-position: inside;
  color: #333;
  font-size: 0.8rem;
  font-weight: 600;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.control-input {
  font-size: 0.875rem;
  width: 100%;
  border: none;
  outline: none;
}
