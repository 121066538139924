@import "../../../styles/config";
@import "../../../styles/mixins";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  &.outdated {
    @include transition(opacity 0.5s);

    opacity: 0.3;
  }

  &.outdated:hover {
    opacity: 0.8;
  }
}

.title {
  color: $black;
  padding: 1rem 1rem 1rem 0.5rem;
  text-align: start;
  text-transform: capitalize;
  width: 100%;
}

.frame {
  padding: 1rem 0.5rem 1rem 1rem;

  .image {
    width: 48px;
    aspect-ratio: 1 / 1;
    object-fit: scale-down;
    object-position: center;
    padding: 0.25rem;
    border: 1px solid $color-table-border-lightest;
    background-color: $white;
  }
}
