@import "../../../styles/config";
@import "./variables";

.container {
  align-items: center;
  background-color: rgb(255 255 255 / 100%);
  border-radius: 0.25rem;
  display: flex;
  filter: drop-shadow(1px 1px 3px $color-table-background-darker);
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  min-height: $table-cell-height;
  min-width: $table-cell-width;

  &:hover {
    background-color: aliceblue;
    cursor: pointer;
  }

  > * + * {
    height: $table-cell-height;
  }
}
