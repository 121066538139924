@import "../../../styles/_config";
@import "../../../styles/_animations";

.closer {
  font-family: inherit;
  border-radius: 100%;
  height: 1.5rem;
  width: 1.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $color-text-dark;
  position: absolute;
  top: 2rem;
  right: 2rem;
  transform: translate(+50%, -50%);

  &:hover {
    color: $color-success;
    cursor: pointer;
    background-color: rgba($color-success-lighter, 0.25);
  }

  &:focus {
    color: $color-success;
    box-shadow: 0 0 0 2px $color-success-light;
  }
}
