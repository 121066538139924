@import '../../styles/config'
@import '../../styles/mixins'
@import '../../styles/form'

.match-item
  // border: 1px solid transparent
  @include transition(box-shadow .5s)
  .outdated-warn
    box-sizing: border-box
    margin-top: -3px
    margin-right: .3rem
    margin-left: .3rem
    padding: 0
    display: inline-block
    vertical-align: middle

  &.outdated
    .main-info, .details
      @include transition(opacity .5s)
      opacity: .3

  &.outdated:hover
    .main-info, .details
      opacity: .8

  .match-error
    display: inline-flex
    align-items: center
    color: $accent

.match-item:hover
  // border: 1px solid #e1e1e1
  // @include transition(box-shadow .4s ease-in)
  // box-shadow: 0 0 5px 0px #d1e1d1 inset
  // background-color: #fafffa
  box-shadow: inset 0px 0px 5px 5px rgba(0, 128, 128, 0.1)



.suggested-match-item

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif

  @media(max-width: $lg)
    padding: 0 1rem 0.5rem 0

  .ean-badge, .match-badge, .review-badge
    margin-top: 12px

  .top-button
    padding: 3px
    margin: 8px 8px 0 0

  .dx-label.dx-match-badge
    display: inline-flex
    align-items: center
    justify-content: center
    box-sizing: border-box
    margin-top: 8px
    margin-right: 8px
    max-width: 6rem
    min-width: 6rem

  .image-container
    position: relative
    text-align: center
    margin-right: 1rem
    .dx-label
      position: absolute
      bottom: 0
      left: 0
      right: 0
      margin: auto
      max-width: 6rem
      opacity: .9
      text-align: center
      @include transition(opacity .5s)

      &.dx-label-manual, &.dx-label-customer-manual
        background-color: $manual-color
      &.dx-label-ean, &.dx-label-daltix-ean
        background-color: $ean-color
      &.dx-label-migrated, &.dx-label-customer-migrated
        background-color: $migrated-color
      &.dx-label-suggestion, &.dx-label-daltix-recordlinkage
        background-color: $suggestion-color
      &.dx-label-daltix-country
        background-color: $country-match-color
      &.dx-label-daltix-shopmapping
        background-color: $shop-match-color
    .picture
      width: 100px
      height: 100px
      min-width: 100px
      min-height: 100px
      background-size: contain
      object-fit: contain
      @include transition(opacity, .5s)

  .text
    @include transition(opacity, .5s)

  a.match-title
    font-size: 1.07em
    color: $text-color-black
    font-weight: 500
    text-decoration: none
    margin-right: 1em
    @include transition(color .5s)

  a.match-title:hover
    color: $primary

  .price
    font-size: 1.3em
    font-weight: 550
    margin-top: -.3em
    margin-bottom: .2em

    .price-part
      display: inline-flex
      flex-direction: column
      justify-content: center
      align-items: center
      margin-right: .4em

      .prices-label
        line-height: 1
        font-weight: 300
        font-size: .6em
        color: $text-color-grey
      .prices-label.factor
        margin-left: -.3rem

      .no-price
        color: $text-color-grey
        font-weight: normal

      .overriden
        text-decoration: line-through
        color: $text-color-grey

      .normalized-price
        font-weight: 300
        font-size: .7em
        color: $text-color-grey

      button.factor-button
        color: $text-color-grey
        height: 1.3rem
        padding: 0
        font-size: .75em
        font-weight: normal
        line-height: 1
        min-width: 4rem
        margin-top: -.2rem

  .price-indicator
    font-size: 1.5em
    margin-bottom: -.3em

  .price-indicator.greater
    color: $primary

  .price-indicator.lower
    color: $color-danger

  .ean
    color: $primary
    font-weight: 500
    .ean-icon
      margin-bottom: -.3em
      margin-right: .2em
    .barcode
      display: inline-block

      & > svg
        height: 2em
        margin-right: 1em
        // border-radius: .3em;
        background-color: #f5f5f5
        // box-shadow: 2px 2px 4px #bbb;
        padding: .1em .3em

  .content-brand-container
    margin-top: -3px
    line-height: 17.55px
  .brand
    color: $text-color-grey
    display: inline-block
    margin-right: 1em
    font-size: 13px
    .brand-icon
      margin-bottom: -.15em
      margin-right: .2em

  .content
    display: inline-block
    font-size: 13px
    color: #848484
    margin-right: 15px

  // .content::before
  //   content: '('

  // .content::after
  //   content: ')'

  .shop
    color: #4a4a4a
    display: inline-block
    font-size: 14px

    .country
      color: $text-color-grey
      font-size: 12px

    .flag
      margin-bottom: -1em

  .actions
    display: flex
    justify-content: center

    .button
      min-width: 100px
      margin-left: .2rem
      margin-right: .2rem

    .exact-selected
      background-color: $primary
      color: $primary-contrast

    .similar-selected
      background-color: $accent
      color: $accent-contrast

    .number-field
      position: absolute
      right: 1rem
      max-width: 100px

  .description
    font-size: 1.1em

  .ingredients
    background-color: #eee
    padding: .5rem
    color: $text-color-grey
    font-size: 1.0em
    margin-bottom: 1rem

    .label
      color: $text-color-black
      font-weight: 500

  .actions-card
    // background-color: antiquewhite;
    background-color: transparent
    border: none
    box-shadow: none
    flex-shrink: 0
    padding: 0

    .header
      padding: 0

    .content
      padding: 0
  .ean-container
    margin-top: 10px

.suggested-match-item.expanded
  .picture
    min-width: 32em
    min-height: 32em
    background-color: white

  .ean
    .barcode > svg
      height: 3.5em
      padding: .1em .5em

.suggested-match-item.discarded
  text-decoration-line: line-through
  .picture,.text
    opacity: .3

.currency
  margin-right: .1em
