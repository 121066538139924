.pagination {
  text-align: center;
  padding-bottom: 2rem;

  .factor-button {
    button {
      height: 1.3rem;
      line-height: 0.8rem;
      font-size: 0.8rem;
    }
  }
}

.matches-review-panel {
  .loading-area {
    background-color: transparent;
    height: calc(100vh - 8rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
  }
}
