@import '../../styles/config'

.dx-form-group.long-input
  min-width: 300px

.category-item
  .name
    margin-right: .5em
  .count
    color: $text-color-grey
    font-size: .8em
    font-weight: 300

.reference-product-slider

  .ref-prod-search
    margin-bottom: 8px

  .no-products
    display: flex
    flex-direction: column
    font-size: 2em
    color: $text-color-grey
    justify-content: center
    align-items: center

  .action-bar

    min-height: 30px

    flex-grow: 1

    .button
      color: $text-color-grey
      min-width: 150px

    .button:disabled
      color: #dadada

    .next
      margin-left: auto

@media(min-width: $md)

  .reference-product-slider, .matches-panel

    height: 100%

    .action-bar
      min-height: 39px
      flex-grow: inherit
      margin-top: 9px
      margin-bottom: 0px


