.dx-status-chip-label
  display: flex
  flex-direction: row
  padding: 2px !important
  align-items: center

.dx-circled-icon
  color: rgba(0, 0, 0, 0.56)
  background-color: rgba(0, 0, 0, 0.08)
  border-radius: 50%
  width: 16px !important
  height: 16px !important
  padding: 2px !important
  font-weight: 50
  margin-right: 2px
