@use "sass:color";
@import "../../../styles/_config";

.slider-root {
  align-items: center;
  display: flex;
  height: 20px;
  margin: 2rem 1rem 1.25rem;
  position: relative;
  touch-action: none;
  user-select: none;
  width: 100%;
}

.slider-track {
  background-color: color.adjust($grey, $lightness: - 50%);
  border-radius: 9999px;
  flex-grow: 1;
  height: 3px;
  position: relative;
}

.slider-range {
  background-color: $secondary;
  border-radius: 9999px;
  height: 100%;
  position: absolute;
}

.slider-thumb {
  background-color: $white;
  border: 1px solid $secondary;
  border-radius: 10px;
  display: block;
  height: 20px;
  width: 20px;
}

.slider-thumb:hover {
  background-color: $secondary;
}

.slider-thumb:focus {
  box-shadow: 0 0 0 5px rgba($secondary, 0.5);
  outline: none;
}

/* Position tooltip above the element */
[data-tooltip]::before {
  border-radius: 3px;
  bottom: 100%;
  content: attr(data-tooltip);
  font-size: $font-sm;
  left: 50%;
  padding: 0.25rem;
  position: absolute;
  text-align: center;
  top: 0;
  transform: translate(-50%, -30px);
}
