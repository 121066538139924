@import "./variables";

.item {
  display: flex;
  height: $table-cell-height;
  position: relative;
  width: 100%;
}

.item.available {
  opacity: 1;
}

.item.unvailable {
  opacity: 0.5;
}
