@import "_config";

.absolute {
  position: absolute;
}

.bg-transparent {
  background-color: transparent;
}

.cursor-auto {
  cursor: auto;
}

.cursor-context-menu {
  cursor: context-menu;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-wait {
  cursor: wait;
}

.cursor-text {
  cursor: text;
}

.divide-x {
  > * + * {
    border-left: 1px solid $color-sidebar-lines;
  }
}

.divide-y {
  > * + * {
    border-top: 1px solid $color-sidebar-lines;
  }
}

/* Allow the input element to grow and take available space */
.flex-grow {
  flex-grow: 1;
}

/* Allow the input element to grow and take available space, but max-width can be altered */
.flex-grow-controlled {
  flex: 1;
  flex-basis: 0;
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.relative {
  position: relative;
}

.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.text-bold {
  font-weight: 600;
}

.text-color {
  color: $color-text;
}

.text-color-dark {
  color: $color-text-dark;
}

.text-color-light {
  color: $color-text-light;
}

.text-color-primary {
  color: $color-text-primary;
}

.text-color-primary-contrast {
  color: $primary-contrast;
}

.text-color-secondary {
  color: $color-text-secondary;
}

.text-color-accent {
  color: $color-text-accent;
}

.text-strikethrough {
  text-decoration: line-through;
}
