@import "../../../styles/config";
@import "../../../styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.9rem;
  font-weight: 400;
  width: 22rem;
  word-break: break-word;

  &.outdated {
    @include transition(opacity 0.5s);

    opacity: 0.3;
  }

  &.outdated:hover {
    opacity: 0.8;
  }
}

.cell {
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  font-weight: 400;
  justify-content: flex-start;
  margin-top: 0.5rem;
  text-align: left;
  width: 100%;
  padding-inline: 0;

  > * {
    padding-inline: 1rem;
  }

  .heading {
    border-bottom: 1px solid $color-table-border-lightest;
    min-height: 1.5rem;
    padding-block: 0.125rem;
    user-select: none;
    width: 100%;
    color: $color-text-light;
  }

  .info {
    color: $black;
    column-gap: 0.25rem;
    display: flex;
    justify-content: flex-start;
    margin-top: 0.75rem;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .upper {
    text-transform: uppercase;
  }

  .scrollable {
    @include scrollbar;
  }

  .badge {
    color: $white;
    background-color: #444;
    border-radius: 0.25rem;
    font-size: 12px;
    height: 24px;
    overflow: hidden;
    padding: 5px;
    text-align: center;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
    width: 40px;
  }
}
