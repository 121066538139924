@import '../../styles/config'
@import '../../styles/_mixins.scss'
  
.add-matches-panel

  margin-top: 0

  .add-matches-content
    padding-bottom: 0

  .tabs
    margin-top: -2em
    width: 100%

  .expansion-panel-icon
    margin-bottom: -.25em

  .expansion-panel-subtitle:hover
    opacity: 1
  .expansion-panel-subtitle
    font-size: 1em
    color: $primary
    opacity: .5
    @include transition(opacity .5s)
    span
      margin-left: 2em
    .expansion-panel-icon
      margin-bottom: -.2em
