@import "../../styles/config";

.auto-complete-container {
  // min-width: 60em;

  display: flex;
  flex-direction: column;
  width: fit-content;
  z-index: 100;

  .item {
    height: auto;
    padding-top: 0.4em;
    padding-bottom: 0.4em;

    .card {
      display: flex;
      width: 100%;
      align-items: center;

      .image {
        width: 3em;
        height: 3em;
        display: inline-block;
        object-fit: contain;
        margin-right: 1em;
      }

      .text {
        display: inline-flex;
        flex-direction: column;
        flex-grow: 1;
        font-size: 0.7em;

        .title {
          font-size: 1.3em;
        }

        .brand {
          display: inline;
          font-size: 1em;
          margin-right: 1em;
          color: $text-color-grey;
        }

        .shop {
          display: inline;
          font-size: 1em;
          color: $accent;
        }

        .ean {
          font-size: 1em;
          color: $primary;
        }
      }
    }
  }

  .more-products-message {
    text-align: center;
    color: $text-color-grey;
    padding-bottom: 5px;
  }
}
