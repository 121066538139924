@import "../../styles/config";
@import "../../styles/mixins";
@import "../../styles/form";

.product-listing {
  margin-top: 0.56em;

  .dx-reference-price {
    margin-top: 0;
  }

  .dx-reference-article-link {
    margin-top: 0;
  }
}

.dx-table-line.match {
  @include transition(box-shadow 0.5s);

  &:hover {
    // background-color: #FAFFFA
    box-shadow: 0 0 10px 10px rgb(0 128 128 / 10%);
  }

  &.discarded,
  &.discarded_by_mops {
    .dx-match-summary {
      .dx-reference-info {
        h3 {
          text-decoration: line-through;
          text-decoration-color: #f00;
        }
      }
    }
  }
}

.dx-factor {
  margin-bottom: 0;

  .dx-factor-part.dx-price-crossed {
    color: #848484;
  }
}

.dx-label {
  &.dx-label-manual,
  &.dx-label-customer-manual {
    background-color: $manual-color;
  }

  &.dx-label-ean,
  &.dx-label-daltix-ean {
    background-color: $ean-color;
  }

  &.dx-label-migrated,
  &.dx-label-customer-migrated {
    background-color: $migrated-color;
  }

  &.dx-label-suggestion,
  &.dx-label-daltix-recordlinkage {
    background-color: $suggestion-color;
  }

  &.dx-label-daltix-country {
    background-color: $country-match-color;
  }

  &.dx-label-daltix-shopmapping {
    background-color: $shop-match-color;
  }
}

.dx-match-image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 150px;

  .dx-label {
    position: absolute;
    bottom: 0;
    left: 1em;
    right: 1em;
    opacity: 0.9;
    @include transition(opacity 0.5s);
  }
}

.dx-match-image:hover {
  .dx-label {
    opacity: 1;
  }
}

.dx-reference-summary {
  flex-direction: column;

  .main-info {
    width: 100%;
    flex-grow: 1;
    display: flex;
    word-break: break-all;
    word-wrap: break-word;

    .dx-reference-image {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      // min-width: 160px
      // min-height: 160px
      min-width: unset;
      min-height: unset;
      max-height: unset;
      width: unset;

      .picture {
        background-size: contain;
        object-fit: contain;
        min-width: 150px;
        min-height: 150px;
        max-width: 150px;
        max-height: 150px;
        @include transition(opacity, 0.5s);
      }
    }
  }

  .details {
    .description {
      font-size: 0.8rem;
    }

    .ingredients {
      background-color: #eee;
      padding: 0.5rem;
      color: $text-color-grey;
      font-size: 0.7rem;
      margin-bottom: 1rem;
    }
  }

  &.expanded {
    .dx-reference-image {
      .picture {
        min-width: 30em;
        min-height: 30em;
        background-color: white;
      }
    }
  }
}

.dx-reference-summary,
.dx-match-summary {
  h3 {
    font-size: 16px;
  }

  .dx-match-country {
    color: #aaa;
    opacity: 1;
  }
}

.dx-match-summary {
  .dx-match-article-link {
    margin-top: 0;
  }

  .outdated-warn {
    box-sizing: border-box;
    margin-bottom: -2px;
    margin-right: 0.3rem;
    margin-left: 0.3rem;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
  }

  &.outdated {
    @include transition(opacity 0.5s);

    opacity: 0.3;
  }

  &.outdated:hover {
    opacity: 0.8;
  }
}

.dx-results {
  font-size: 12px;
}

.currency {
  margin-right: 0.1em;
}
