@import "../../../../styles/config";

.container {
  align-items: center;
  display: flex;
  height: 56px;
  justify-content: flex-start;
  padding-block: 0.25rem;
  padding-inline: 1rem;
  width: 18rem;
}
