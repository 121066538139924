@import "../../../../../styles/config";

.highlighted {
  color: $color-text-primary;
  font-weight: 600;
}

.text-container {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
