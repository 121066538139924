@import "../../../styles/config";

$area-bar-space-h: 2.5em;
$radius-top-menu: 0.3em;
$time-interaction: 0.3s;

.area-bar {
  flex: 0 0 auto;
  padding: 1.7em $area-bar-space-h 1.5em;
  text-align: left;
  border-bottom: 1px solid #d1d1d1;

  h1 {
    display: inline-block;
    margin: 0 calc($area-bar-space-h / 2.6) 0 0;
    padding: 0;
    font-size: 2.6em;
    line-height: 1.15;
    text-transform: none;
  }

  .area-bar-filter-button {
    position: relative;
    top: -0.2em;
  }
}

.area-bar .filter-area {
  height: auto;
  margin: 0;
  padding: 0;
  transition:
    height $time-interaction,
    opacity $time-interaction;
  opacity: 1;

  &.filter-collapse {
    height: 0;
    opacity: 0;
    overflow: hidden;
  }
}
