@import '../../styles/config'
@import '../../styles/mixins'
@import '../../styles/form'

.match-root
  display: flex
  width: 100%

  .match-item-root
    flex-grow: 1
    display: flex
    flex-direction: column

    .main-info
      display: flex
      word-break: break-all
      word-wrap: break-word

      .dx-reference-info
        text-align: left

        h3
          font-size: 1.17em
          margin-before: 1em
          margin-after: 1em

      .picture-container
        position: relative
        display: flex
        align-items: center
        justify-content: center
        min-width: 160px
        min-height: 160px
        .dx-label
          position: absolute
          bottom: 0
          left: 1em
          right: 1em
          opacity: .9
          margin: auto
          text-align: center
          max-width: 6rem
          @include transition(opacity .5s)
        .dx-label-status
          position: absolute
          bottom: 0
          background-color: #fff
          opacity: .9
          margin: auto
          text-align: center
          @include transition(opacity .5s)

        .picture
          background-size: contain
          object-fit: contain
          min-width: 150px
          min-height: 150px
          max-width: 150px
          max-height: 150px
          @include transition(opacity, .5s)

    .description
      font-size: 0.8rem

    .dx-product-source
      font-size: 24px
      margin-bottom: -6px
      margin-left: .3rem

    .ingredients
      background-color: #eee
      padding: .5rem
      color: $text-color-grey
      font-size: 0.7rem
      margin-bottom: 1rem

    &.expanded
      .picture-container
        min-width: unset
        min-height: unset
        .picture
          min-width: 30em
          min-height: 30em
          background-color: white

  .actions
    flex-grow: 0
    width: 140px
    display: flex
    justify-content: center
    align-items: center

.tooltip
  font-size: .8rem
  .action
    font-weight: bold
  .property
    .value
      font-weight: bold
