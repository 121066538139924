@import "../../../../styles/_config";

.footer {
  flex: 0 0 auto;
  padding-inline: 0;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
  }
}
