@import "../../../styles/config";

.link {
  color: $color-text-secondary;
  text-decoration: none;

  &:hover,
  &:focus-visible {
    text-decoration: underline;
    text-underline-offset: 0.125rem;
    outline: none;
  }
}
