@import "../../../styles/config";
@import "../../../styles/mixins";

.title {
  color: $black;
  padding: 1rem;
  text-align: start;
  text-transform: capitalize;
  width: 100%;

  &.outdated {
    @include transition(opacity 0.5s);

    opacity: 0.3;
  }

  &.outdated:hover {
    opacity: 0.8;
  }
}
