@import "../../../styles/config";
@import "../../../styles/mixins";

.controls {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 0.5rem;
  min-height: 3rem;
  padding-inline: 1rem;

  &.outdated {
    @include transition(opacity 0.5s);

    opacity: 0.3;
  }

  &.outdated:hover {
    opacity: 0.8;
  }
}
