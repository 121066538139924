// CSS Reset should be the first import among all imports
@import "_reset";

@import "_animations";
@import "_config";
@import "_form";
@import "_utilities";
@import "fonts";
@import "mixins";

*::-webkit-scrollbar {
  background-color: transparent;
  width: $scroll-width;
  height: $scroll-width;
  border-radius: $scroll-width;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: $color-sidebar;
  border-radius: $scroll-radius;
  width: $scroll-width;

  &:hover {
    background-color: $color-sidebar-hover;
  }
}

.app-bar.header {
  display: flex;
  height: 50px;
  padding: 0;
  color: #fff;
  background: linear-gradient(45deg, #18a191 0, #3de2cf 100%);
  box-shadow: 0 2px 4px rgba(31 205 185 / 50%);
  z-index: 1101;
  overflow: visible;

  .icon-button {
    padding: 5px;
    margin-left: 0;
  }

  h1 {
    display: inline-block;
    margin-top: -0.5em;
    margin-left: 10px;

    a {
      color: #fff;
      text-decoration: none;
    }

    .app-name {
      margin-left: 2px;
      font-weight: 300;
    }
  }
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, Graphie, Oxygen, Ubuntu, Cantarell,
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.7rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.center {
  text-align: center;
}

.loader-container {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.acordion-item-first {
  margin-top: 0;
}

.aux-text {
  color: $text-color-grey;
}

.expandable {
  @include transition(
    width 0.3s ease-in-out,
    height 0.3s ease-in-out,
    min-width 0.3s ease-in-out,
    min-height 0.3s ease-in-out
  );
}

.centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text-right input {
  text-align: right;
}

.vertical-separator {
  width: 1px;
  margin: 0 auto;
  height: 90%;
  border-left: 1px $text-color-grey solid;
}

.factor-button {
  display: inline-block;
  font-size: 1em;

  button {
    font-size: 1em;
    padding: 0.2em;
  }
}

.page-bar {
  @media (max-width: $lg) {
    min-height: 3rem;
  }
}

.logo-head-icon {
  width: 8.3em;
  background-size: cover;
  margin: 0.8em -1.5em 0 -1.8em;
  filter: drop-shadow(0 2px 3px #a2854a);
}

.menu-button {
  color: $primary !important;
  min-width: 7em;
  margin-right: 1em;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 2px transparent solid;
  @include transition(border-bottom-color 0.4s);
}

.menu-button.selected {
  border-bottom-color: $primary;
}

.main-content-panel {
  padding: 20px;
}

.profile-avatar {
  width: 24px;
  height: 24px;
}

.multi-select-container {
  min-width: 100px;
  margin-right: 15px;
}

.ean-badge,
.match-badge,
.review-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  fill: currentcolor;
  width: 3em;
  margin: 8px 8px 0 0;
  height: 1.4em;
  font-size: 14px;
  background-color: $primary;
  color: $primary-contrast;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  user-select: none;
  flex-shrink: 0;
  border-radius: 0.2em;
}

.match-badge {
  width: 5em;
  background-color: deepskyblue;
}

.review-badge {
  width: 6em;
  background-color: grey;
}

.loading-container {
  width: 100%;
}

.matches-box-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 38em);
  justify-content: center;
  align-items: center;
  width: 100%;
}

.suggested-matches-list {
  flex-grow: 1;

  @include transition(all 1s ease-out);

  height: calc(100vh - 21rem);
  overflow-y: scroll;

  .container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .main-info {
      display: flex;
    }
  }

  &.read-only {
    height: calc(100vh - 18rem);
  }
}

.review-list {
  flex-grow: 1;
  padding: 20px;

  .list-container {
    flex-grow: 1;
  }

  .review-item {
    .picture-container {
      padding-right: 16px;

      .picture {
        width: 70px;
        height: 70px;
        background-size: contain;
      }
    }

    .title {
      font-size: 1.3em;
      margin-bottom: 0.1em;
      font-weight: 500;
    }

    .brand {
      margin: 0;
      color: $text-color-grey;
    }

    .volume {
      margin: 0;
      color: $text-color-grey;
    }

    .price {
      font-weight: 500;
    }

    .matches-column {
      padding-top: 20px;

      .matches {
        color: $text-color-grey;
        margin: 0.2em;
      }

      .matches-last-update {
        color: $text-color-grey;
        font-size: 0.7em;
      }
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: $md) {
  html,
  body,
  #root {
    height: 100%;
  }

  .fill-height-container {
    display: flex;
    flex-direction: column;
  }

  .fill-height {
    flex-grow: 1;
  }

  .scroll {
    overflow-y: auto;
  }

  .root {
    height: 100%;
    display: flex;
    flex-flow: column;
  }

  .main-content-panel {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-bottom: 16px !important;
    overflow: hidden;

    .main-content-grid {
      flex-grow: 1;
    }
  }
}

$transition-duration-fast: 0.4s;
$transition-duration-medium: 0.6s;
$transition-duration-slow: 1.5s;
$transition-easy-in: ease-in;
$transition-easy-out: ease-in-out;

.match-appear {
  transform: translateY(500px);
  opacity: 0;
}

.match-appear.match-appear-active {
  opacity: 1;
  transform: translateY(0);
  @include transition(
    opacity $transition-duration-medium $transition-easy-out,
    transform $transition-duration-medium $transition-easy-out
  );
}

.match-enter {
  max-height: 0;
  padding: 0 16px;
  border-bottom: none;
  opacity: 0;
}

.match-enter.match-enter-active {
  opacity: 1;
  max-height: 20em;
  overflow: hidden;
  padding: 11px 16px;
  border-bottom: 1px solid rgba(0 0 0 / 12%);

  @include transition(
    max-height $transition-duration-medium $transition-easy-out,
    opacity $transition-duration-medium $transition-easy-out,
    padding $transition-duration-medium $transition-easy-out,
    border-bottom $transition-duration-medium $transition-easy-out
  );
}

.match-leave,
.match-exit {
  opacity: 1;
  overflow: hidden;
  max-height: 20em;
}

.match-leave.match-leave-active,
.match-exit.match-exit-active {
  opacity: 0;
  max-height: 0;
  padding: 0 16px;
  border: none;
  @include transition(
    max-height $transition-duration-fast $transition-easy-out,
    opacity $transition-duration-fast $transition-easy-out,
    padding $transition-duration-fast $transition-easy-out,
    border $transition-duration-fast $transition-easy-out
  );
}

.screen-fade-enter {
  opacity: 0;
  transition: opacity 1s ease-in;
}

.screen-fade-enter.screen-fade-enter-active {
  opacity: 1;
}

.screen-fade-exit.screen-fade-exit-active {
  display: none !important;
}
